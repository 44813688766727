import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule, LowerCasePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableModule } from '@table/table.module';

// Material (TODO: Remove unused modules)
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
// import { MatStepperModule } from '@angular/material/stepper';
import { MatInputModule } from '@angular/material/input';
// import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
// import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
// import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
// import { MatRadioModule } from '@angular/material/radio';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatTooltipModule } from '@angular/material/tooltip';
// import { MatTabsModule } from '@angular/material/tabs';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';

// Pipes
import { HumanizePipe } from './pipes/humanize.pipe';
import { MinutesToTime } from './pipes/minutes-to-time.pipe';
import { MinutesToNumber } from './pipes/minutes-to-number.pipe';
import { UniquePipe } from './pipes/unique.pipe';
import { CountScanned } from './pipes/count-scanned.pipe';
import { DateAgoPipe } from './pipes/date-ago.pipe';

// Guards
import { PendingChangesGuard } from './guards/pending-changes.guards';

// Components
import { HeaderComponent } from './components/header/header.component';
import { LoaderComponent } from './components/loader/loader.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { MessageComponent } from './components/message/message.component';
import { FiltersSettingsComponent } from './components/filters-settings/filters-settings.component';
import { ActionButtonsComponent } from './components/action-buttons/action-buttons.component';
import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { SearchHeaderFieldComponent } from './components/search-header-field/search-header-field.component';

// Dialogs
import { ScannerFieldDialogComponent } from './dialogs/scanner-field-dialog/scanner-field-dialog.component';
import { WTDDialogComponent } from './dialogs/wtd-dialog/wtd-dialog.component';
import { ConfirmDialogComponent } from './dialogs/confirm-dialog/confirm-dialog.component';

// Sidebars
import { SwitchHubSidebarComponent } from './sidebars/switch-hub-sidebar/switch-hub-sidebar.component';

@NgModule({
  declarations: [
    HumanizePipe,
    MinutesToTime,
    MinutesToNumber,
    UniquePipe,
    CountScanned,
    HeaderComponent,
    LoaderComponent,
    BreadcrumbsComponent,
    MessageComponent,
    FiltersSettingsComponent,
    ActionButtonsComponent,
    SnackbarComponent,
    ScannerFieldDialogComponent,
    WTDDialogComponent,
    ConfirmDialogComponent,
    SwitchHubSidebarComponent,
    DateAgoPipe,
    SearchHeaderFieldComponent,
  ],
  imports: [
    CommonModule,
    TableModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatButtonModule,
    MatDividerModule,
    // MatStepperModule,
    MatInputModule,
    // MatAutocompleteModule,
    MatSelectModule,
    MatFormFieldModule,
    // MatCheckboxModule,
    MatMenuModule,
    MatNativeDateModule,
    // MatDatepickerModule,
    MatDialogModule,
    MatChipsModule,
    MatSlideToggleModule,
    // MatRadioModule,
    DragDropModule,
    // MatTabsModule,
    MatGridListModule,
    MatSnackBarModule,
    MatTooltipModule,
  ],
  exports: [
    HumanizePipe,
    MinutesToTime,
    MinutesToNumber,
    UniquePipe,
    CountScanned,
    HeaderComponent,
    LoaderComponent,
    BreadcrumbsComponent,
    MessageComponent,
    FiltersSettingsComponent,
    ActionButtonsComponent,
    TableModule,
    DateAgoPipe,
    ConfirmDialogComponent,
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        HumanizePipe,
        MinutesToTime,
        MinutesToNumber,
        UniquePipe,
        CountScanned,
        DateAgoPipe,
        LowerCasePipe,
        PendingChangesGuard,
      ],
    };
  }
}
