import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { State } from '@auth/store';
import { Store } from '@ngrx/store';
import { GenericScanResp } from '@core/interfaces/scan-response.interface';
import { Observable, Subject, filter, takeUntil } from 'rxjs';
import { ManifestDetails } from '@operations/features/picking/interfaces/manifest-details.interface';
import {
  selectManifestDetails,
  selectManifestsLoading,
} from '@operations/features/picking/store/selectors/picking.selectors';
import { ActivatedRoute, Router } from '@angular/router';
import { ScannerService } from '@shared/services/scanner.service';
import { ScannerFieldDialogComponent } from '../scanner-field-dialog/scanner-field-dialog.component';
import { selectReceivingLoading } from '@operations/features/receiving/store/selectors/receiving.selectors';
import * as ConsolidStockOutSelectors from '@operations/features/consolidation/store/selectors/stock-out.selectors';
import * as ConsolidScanInSelectors from '@operations/features/consolidation/store/selectors/scan-in.selectors';
import * as CageStockOutSelectors from '@operations/features/cage/store/selectors/stock-out.selectors';
import * as CageScanInSelectors from '@operations/features/cage/store/selectors/scan-in.selectors';
import * as LastMileScanInSelectors from '@operations/features/last-mile/store/selectors/scan-in.selectors';
import * as LastMileRunsheetsSelectors from '@operations/features/last-mile/store/selectors/runsheets.selectors';
import * as LastMileSecurityCheckSelectors from '@operations/features/last-mile/store/selectors/security-check.selectors';
import * as LastMileRunsheetsVerificationSelectors from '@operations/features/last-mile/store/selectors/runsheets-verification.selectors';

@Component({
  selector: 'app-wtd-dialog',
  templateUrl: './wtd-dialog.component.html',
  styleUrls: ['./wtd-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class WTDDialogComponent implements OnInit, OnDestroy {
  @HostListener('window:resize', ['$event']) onResize(event: { target: { innerWidth: number } }): void {
    this.innerWidth = event.target.innerWidth;
    if (event.target.innerWidth <= 768) {
      this.isMobile = true;
      this.setGridBreakPoint();
    }
  }

  private readonly scannerSubmit$: Observable<string>;
  private readonly destroyed$ = new Subject<void>();
  private isConsScanIn: boolean = this.router.url.includes('scan-in');
  private isConsStockOut: boolean = this.router.url.includes('consolidation/stock-out');
  private isPicking: boolean = this.router.url.includes('pickup');
  private isReceiving: boolean = this.router.url.includes('receiving');
  private isCageStockOut: boolean = this.router.url.includes('resolution-center/stock-out');
  private isCageScanIn: boolean = this.router.url.includes('resolution-center/scan-in');
  private isLastMileScanIn: boolean = this.router.url.includes('last-mile/scan-in');
  private isLastMileRunsheet: boolean = this.router.url.includes('last-mile/runsheets');
  private isLastMileSecurityCheck: boolean = this.router.url.includes('last-mile/security-check');
  private isLastMileRunsheetsVerification: boolean = this.router.url.includes('last-mile/runsheets-verification');
  private isFromSearch: boolean = false;

  public readonly manifestDetails$!: Observable<ManifestDetails | null>;
  public readonly loading$!: Observable<boolean | null>;

  public innerWidth: number = window.innerWidth;
  public scannerTrackingNumber: string = '';

  public firstGridBreakpoint: number = 1; // 3;
  public secondGridBreakpoint: number = 2; // 4;
  public thirdGridBreakpoint: number = 3; // 5;
  public columnSpan: number = 2; // 1;

  public isMobile: boolean = false;
  public isHidden: boolean = false;
  public isMinSize: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<WTDDialogComponent>,
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<State>,
    private scannerService: ScannerService,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: GenericScanResp
  ) {
    this.scannerSubmit$ = this.scannerService.newScan$;

    this.route.queryParams.subscribe((params) => {
      if (params['isSearch']) {
        this.isFromSearch = true;
      }
    });

    if (this.isPicking) {
      this.loading$ = this.store.select(selectManifestsLoading);
      this.manifestDetails$ = this.store.select(selectManifestDetails);
    } else if (this.isReceiving) {
      this.loading$ = this.store.select(selectReceivingLoading);
    } else if (this.isConsStockOut) {
      this.loading$ = this.store.select(ConsolidStockOutSelectors.selectLoading);
    } else if (this.isConsScanIn) {
      this.loading$ = this.store.select(ConsolidScanInSelectors.selectLoading);
    } else if (this.isCageStockOut) {
      this.loading$ = this.store.select(CageStockOutSelectors.selectLoading);
    } else if (this.isCageScanIn) {
      this.loading$ = this.store.select(CageScanInSelectors.selectLoading);
    } else if (this.isLastMileScanIn) {
      this.loading$ = this.store.select(LastMileScanInSelectors.selectLoading);
    } else if (this.isLastMileRunsheet) {
      this.loading$ = this.store.select(LastMileRunsheetsSelectors.selectLoading);
    } else if (this.isLastMileSecurityCheck) {
      this.loading$ = this.store.select(LastMileSecurityCheckSelectors.selectLoading);
    } else if (this.isLastMileRunsheetsVerification) {
      this.loading$ = this.store.select(LastMileRunsheetsVerificationSelectors.selectLoading);
    }
  }

  public ngOnInit(): void {
    // Set back focus to scanner input field
    this.dialog.openDialogs.forEach((dialog) => {
      if (dialog.componentInstance instanceof ScannerFieldDialogComponent) {
        setTimeout(() => {
          if (dialog.componentInstance) {
            dialog.componentInstance.barCodeInput.nativeElement.focus({ focusVisible: true });
            this.cdr.detectChanges();
          }
        }, 200);
      }
    });

    this.scannerSubmit$
      .pipe(
        takeUntil(this.destroyed$),
        filter((barCode) => !!barCode)
      )
      .subscribe((barCode) => {
        this.scannerTrackingNumber = barCode;
      });

    if (this.innerWidth <= 768) {
      this.isMobile = true;
      this.setGridBreakPoint();
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public close(): void {
    this.router.navigate([], { relativeTo: this.route, queryParams: { isSearch: null } }).then(() => {
      this.dialogRef.close();
    });
  }

  public getAgeInDays(created: number | undefined): number | 'N/A' {
    if (!created) {
      return 'N/A';
    }
    const todayDate = new Date();
    const createdDate = new Date(created);
    const difference = todayDate.getTime() - createdDate.getTime();
    return Math.ceil(difference / (1000 * 3600 * 24));
  }

  public validateMp(): void {
    if (
      (this.isPicking ||
        this.isReceiving ||
        this.isConsScanIn ||
        this.isConsStockOut ||
        this.isCageStockOut ||
        this.isCageScanIn ||
        this.isLastMileScanIn ||
        this.isLastMileRunsheet ||
        this.isLastMileSecurityCheck ||
        this.isLastMileRunsheetsVerification) &&
      this.data.order?.trackingNumber &&
      this.isFromSearch === false
    ) {
      this.router.navigate([this.router.url, this.data.order?.trackingNumber]);
    } else {
      // This is search MP validation
      // We need double navigation when we are in mp-validation page
      this.router.navigate([`/app/search/${this.data.order?.trackingNumber}`]).then(() =>
        this.router.navigate([`/app/search/${this.data.order?.trackingNumber}/mp-validation`], {
          state: { order: this.data.order },
        })
      );
    }
    this.dialogRef.close();
  }

  public toggleMinSize(): void {
    this.setGridBreakPoint();
    this.isHidden = false;
    this.isMinSize = true;
    // This condition for mobile since there is no maximize button on mobile view it's will be only minimize and 100% size
    if (!this.isMobile) {
      this.dialogRef.updateSize('480px', '100%');
    } else {
      this.dialogRef.updateSize('100%', '100%');
    }

    this.dialogRef.updatePosition({ top: '0px', right: '0px' });
    this.cdr.detectChanges();
  }

  public toggleMaxSize(): void {
    this.resetGridBreakPoint();
    this.isHidden = false;
    this.isMinSize = false;
    this.dialogRef.updateSize('100%', '100%');
    this.dialogRef.updatePosition({ top: '0px', right: '0px' });
    this.cdr.detectChanges();
  }

  public toggleVisibility(): void {
    this.setGridBreakPoint();
    this.isHidden = true;
    this.isMinSize = false;
    this.dialogRef.updateSize('480px', '48px');
    this.dialogRef.updatePosition({ bottom: '0px', right: '0px' });
    this.cdr.detectChanges();
  }

  private setGridBreakPoint(): void {
    this.firstGridBreakpoint = 1;
    this.secondGridBreakpoint = 2;
    this.thirdGridBreakpoint = 3;
    this.columnSpan = 2;
  }

  private resetGridBreakPoint(): void {
    this.firstGridBreakpoint = 3;
    this.secondGridBreakpoint = 4;
    this.thirdGridBreakpoint = 5;
    this.columnSpan = 1;
  }

  public checkIfMPAndNotScanned(): boolean {
    if (
      this.data.order &&
      this.data.order.packages &&
      this.data.order.packages.length > 1 &&
      this.data.order.packages.filter((pk) => pk.scanningStatus !== 'scanned').length > 0
    ) {
      return true;
    } else {
      return false;
    }
  }
}
