<section [ngClass]="{ isHidden: isHidden, isMinSize: isMinSize, isMobile: isMobile}">
  <header
    class="dialog-headline"
    [ngClass]="{'success': (data.status === 'success'), 'error': (data.status === 'error'), 
    'warning': (data.status === 'warning'), 
    'mp': (data.status === 'warning'  && checkIfMPAndNotScanned()),
    'loading': (loading$ | async) }"
  >
    <div>
      <button mat-icon-button class="icon-close" (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
      <h1>
        {{ (data.status === 'warning' && checkIfMPAndNotScanned()) ? 'Multi package' : (data.status | humanize) }}
      </h1>
    </div>

    <div class="size-btns">
      <button mat-icon-button *ngIf="!isMobile" (click)="toggleMaxSize()">
        <mat-icon [svgIcon]="'maxSize'" [ngClass]="{ 'isHidden': isHidden }"></mat-icon>
      </button>
      <button mat-icon-button (click)="toggleMinSize()">
        <mat-icon [svgIcon]="'minSize'" [ngClass]="{ 'isHidden': isHidden }"></mat-icon>
      </button>
      <button mat-icon-button (click)="toggleVisibility()">
        <mat-icon [svgIcon]="'minimize'" [ngClass]="{ 'isHidden': isHidden }"></mat-icon>
      </button>
    </div>
  </header>

  <div *ngIf="(loading$ | async); else content" class="loader-wrapper">
    <app-loader class="loader--large"></app-loader>
  </div>

  <ng-template #content>
    <h2
      class="title body"
      [ngClass]="{'success': (data.status === 'success'), 'error': (data.status === 'error'), 'warning': (data.status === 'warning'),
      'mp': (data.status === 'warning' &&  checkIfMPAndNotScanned() )}"
    >
      <ng-container *ngIf="data.order && data.order.packages.length > 1 else noMp">
        <span>
          {{ ((isMobile || isMinSize) ? 'MP: ' : 'Multi Package: ')}}&nbsp;
          <a [routerLink]="['/app/search/', scannerTrackingNumber || data.order.trackingNumber]"
            >{{scannerTrackingNumber || data.order.trackingNumber}}</a
          >&nbsp;{{data.order.nextArea | humanize}}
        </span>
      </ng-container>
      <ng-template #noMp>
        <span>
          <a [routerLink]="['/app/search/', scannerTrackingNumber || data.order?.trackingNumber]"
            >{{scannerTrackingNumber || data.order?.trackingNumber}}</a
          >&nbsp;{{data.order?.nextArea | humanize}}
        </span>
      </ng-template>
      <!-- {{ (data.order && data.order.packages.length > 1) ? ((isMobile || isMinSize) ? 'MP: ' : 'Multi Package: ') +
      scannerTrackingNumber : scannerTrackingNumber }} -->
      <span class="mp-count" *ngIf=" data.order && data.order.packages">
        {{ data.order.packages.length }}&nbsp;
        <span *ngIf="!isMobile && !isMinSize">{{ data.order.packages.length === 1 ? 'Package' : 'Packages' }}</span>
        <span *ngIf="isMobile || isMinSize"> PKGs</span>
      </span>
    </h2>

    <ng-container *ngIf="data.status === 'error'">
      <div class="failed body">
        <mat-icon class="error-icon" svgIcon="radioactive"></mat-icon>
        <h3 class="head1">{{ data.error ? data.error : data.message }}</h3>
      </div>
    </ng-container>

    <ng-container *ngIf="data.status === 'warning' && !checkIfMPAndNotScanned()">
      <div class="failed warn body">
        <mat-icon class="warning-icon" svgIcon="alertdiamond"></mat-icon>
        <h3 class="head1 warn">{{ data.warning ? data.warning : data.message }}</h3>
      </div>
    </ng-container>

    <ng-container *ngIf="data.status === 'success' || ( data.status === 'warning' && checkIfMPAndNotScanned() )">
      <mat-grid-list class="grid-list" [cols]="firstGridBreakpoint" rowHeight="fit">
        <mat-grid-tile>
          <div class="info">
            <h3 *ngIf="data.manifestId || (manifestDetails$ | async)?.id">Manifest</h3>
            <p *ngIf="data.manifestId || (manifestDetails$ | async)?.id">
              {{ data.manifestId? data.manifestId: (manifestDetails$| async)?.id }}
            </p>

            <h3 *ngIf="!data.manifestId && !(manifestDetails$ | async)?.id">Shipment</h3>
            <p *ngIf="!data.manifestId && !(manifestDetails$ | async)?.id">{{ data.order?.trackingNumber }}</p>
          </div>
        </mat-grid-tile>

        <mat-grid-tile>
          <div class="info">
            <h3>Type</h3>
            <p>{{ data.order?.category || 'N/A' }}</p>
          </div>
        </mat-grid-tile>

        <mat-grid-tile>
          <div class="info">
            <h3>Status</h3>
            <p>{{(data.order?.storyStatus | humanize) || (data.status | humanize) || 'N/A'}}</p>
            <!-- <p>{{ data.status }}</p> -->
          </div>
        </mat-grid-tile>
      </mat-grid-list>

      <mat-grid-list class="grid-list gray" [cols]="secondGridBreakpoint" rowHeight="fit">
        <mat-grid-tile>
          <div class="info">
            <h3>Aging</h3>
            <p>{{ getAgeInDays(data.order?.created) }} days</p>
          </div>
        </mat-grid-tile>

        <mat-grid-tile>
          <div class="info">
            <h3>Attempts</h3>
            <p>N/A</p>
          </div>
        </mat-grid-tile>

        <mat-grid-tile>
          <div class="info">
            <h3>Weight</h3>
            <p>{{ data.order?.weight || 'N/A' }}</p>
          </div>
        </mat-grid-tile>

        <mat-grid-tile>
          <div class="info">
            <h3>Counter</h3>
            <p>{{ (data.order?.packages | countScanned) }} / {{data.order?.packages?.length}}</p>
            <!-- <p>
              {{ (data.scanned) ? data.scanned : data.order?.manifest?.scanned }} / {{ (data.total) ? data.total :
              data.order?.manifest?.total }}
            </p> -->
          </div>
        </mat-grid-tile>
      </mat-grid-list>

      <mat-grid-list class="grid-list" [cols]="thirdGridBreakpoint" rowHeight="fit">
        <mat-grid-tile [colspan]="columnSpan">
          <div class="info">
            <h3>Hub</h3>
            <p>{{ data.order?.dropOffZoneName || 'N/A' }}</p>
          </div>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="1">
          <div class="info">
            <h3>Zone</h3>
            <p>{{ data.order?.dropOffZoneName || 'N/A' }}</p>
          </div>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="1">
          <div class="info">
            <div>
              <h3>Shelf</h3>
              <p>{{ data.order?.shelfId || 'N/A' }}</p>
            </div>
          </div>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="1">
          <div class="info">
            <h3>City</h3>
            <p>{{ data.order?.dropOffCity || 'N/A' }}</p>
          </div>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="1">
          <div class="info">
            <h3>Country</h3>
            <p>{{ data.order?.dropOffCountry || 'N/A' }}</p>
          </div>
        </mat-grid-tile>
      </mat-grid-list>

      <!-- <div class="buttons-wrapper" *ngIf="!this.isScanIn && checkIfMPAndNotScanned()"> -->
      <div class="buttons-wrapper" *ngIf="checkIfMPAndNotScanned()">
        <button mat-button color="accent" class="reset-btn" (click)="close()">Validate later</button>
        <button mat-flat-button color="accent" class="submit-btn" (click)="validateMp()">Validate now</button>
      </div>
    </ng-container>
  </ng-template>
</section>
