import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewChild,
  ViewContainerRef,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { Observable, Subject, takeUntil } from 'rxjs';
import { LoaderService } from '@core/services/loader.service';
import { CustomIconService } from '@core/services/custom-icon.service';
import { MatSidenav } from '@angular/material/sidenav';
import { SidebarLoaderService } from '@shared/services/sidebar-loader.service';
import { SideBarComponents } from '@shared/types/side-bar-components.type';
import { Router } from '@angular/router';

@Component({
  selector: 'app-app-root',
  templateUrl: './app-root.component.html',
  styleUrls: ['./app-root.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppRootComponent implements OnInit, OnDestroy {
  @ViewChild('sidebar') sidebar!: MatSidenav;
  @ViewChild('sideBarContainer', { read: ViewContainerRef }) private sideBarContainerRef!: ViewContainerRef;
  public loading = false;
  public readonly loading$: Observable<boolean>;

  private readonly destroyed$ = new Subject<void>();

  constructor(
    private loaderService: LoaderService,
    private customIconService: CustomIconService,
    private cdr: ChangeDetectorRef,
    private sidebarLoaderService: SidebarLoaderService,
    private router: Router
  ) {
    this.customIconService.registerIcons();

    this.sidebarLoaderService.sideBarComp$.pipe(takeUntil(this.destroyed$)).subscribe((comp: SideBarComponents) => {
      this.toggleSideNav(comp);
      cdr.detectChanges();
    });
    this.sidebarLoaderService.destroyComp$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.onSidebarChange(false);
      // this.sidebar.opened = false;
      this.sidebar.close();
      cdr.detectChanges();
    });

    this.loading$ = this.loaderService.isShown$;
  }

  ngOnInit(): void {
    this.loading$.pipe(takeUntil(this.destroyed$)).subscribe((loading: boolean) => {
      this.loading = loading;
      this.cdr.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public onSidebarChange(isOpen: boolean): void {
    if (!isOpen) {
      this.sideBarContainerRef.remove();
      this.sideBarContainerRef.clear();
    }
    this.cdr.detectChanges();
  }

  public onSidenavClose(): void {
    this.sidebarLoaderService.destroySideBarComp();
    this.router.navigate([], { queryParams: { trackingNumber: null }, queryParamsHandling: 'merge' });
  }

  private toggleSideNav(sidebarComp: SideBarComponents): void {
    this.sidebarLoaderService.loadComponent(this.sideBarContainerRef, sidebarComp).then(() => {
      // this.sidebar.toggle();
      this.sidebar.open();
      this.cdr.detectChanges();
    });
  }
}
